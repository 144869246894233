/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';
import { COLORS } from '@headout/fresh-aer';

import colors from 'Static/typography/colors';
import fonts from 'Static/typography/fonts';
import TYPE_LABELS from 'Static/typography/labels';

export const ProductCard = styled.div<{
	isComparisonTable?: boolean;
	fullWidthCards?: boolean;
	isTopPicks?: boolean;
	verticalGapBetweenCard?: boolean;
	isProductPage?: boolean;
	highlighted?: boolean;
}>`
	position: relative;
	display: flex;
	width: ${({ isComparisonTable, fullWidthCards, isTopPicks }) =>
		fullWidthCards && isTopPicks
			? 'calc(100vw - 2.125rem)'
			: fullWidthCards
			? 'calc(100vw - 2.5rem)'
			: isComparisonTable
			? '24rem'
			: '17.625rem'};
	cursor: pointer;
	outline: none;
	margin: ${props => ((props as any).isProductPage ? '0.75rem' : 0)};

	a {
		color: ${colors.GREY['44']};
	}

	&:active:not(:focus-within) {
		transition: transform 0.35s ease-in-out 0s;
		transform: scale(0.96);
	}
	@media (max-width: 768px) {
		margin: ${props => ((props as any).isProductPage ? '0 1rem 0 0' : 0)};
		width: ${({ isComparisonTable, fullWidthCards, isTopPicks }) =>
			fullWidthCards || isTopPicks
				? 'calc(100vw - 3rem)'
				: isComparisonTable
				? '24rem'
				: '17rem'};

		${({ verticalGapBetweenCard }) =>
			verticalGapBetweenCard && `margin-bottom: 2rem;`};
	}

	@media (min-width: 768px) {
		top: 0;

		&:hover {
			top: -0.1875rem;
			transition: top 0.2s ease-in-out 0s;
		}
	}

	${({ highlighted }) =>
		highlighted &&
		css`
			width: 100% !important;
			padding: 0.75rem;
			background-color: ${COLORS.BACKGROUND.FLOATING_PURPS};
			border: 1px solid ${COLORS.PURPS_LEVEL[20]};
			border-radius: 0.75rem;
		`}
`;

export const CardContainer = styled.div<{
	isComparisonTable?: boolean;
	fullWidthCards?: boolean;
}>`
	display: grid;
	grid-row-gap: 0.5rem;
	grid-template-rows: ${({ isComparisonTable }) =>
		isComparisonTable ? `auto` : '11rem auto'};
	width: 100%;
	align-content: start;
	position: relative;
	@media (max-width: 768px) {
		grid-template-rows: ${({ fullWidthCards }) =>
			fullWidthCards ? `auto` : '10.5rem auto'};
	}
`;

export const ProductImage = styled.div<{
	$carousel?: boolean;
	isTopPicks?: boolean;
	isComparisonTable?: boolean;
	$isPinnedCard?: boolean;
	$fullWidthCards?: boolean;
}>`
	overflow: hidden;
	isolation: isolate;
	border-radius: ${({ $carousel }) => ($carousel ? '0.5rem' : '0.25rem')};
	${({ isTopPicks }) =>
		isTopPicks &&
		`
        border-top-right-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    `}

	min-height: ${({ isComparisonTable }) =>
		isComparisonTable ? '15rem' : '11rem'};
	width: ${({ isComparisonTable, $fullWidthCards, isTopPicks }) =>
		$fullWidthCards && isTopPicks
			? 'calc(100vw - 2.125rem)'
			: $fullWidthCards
			? 'calc(100vw - 2.5rem)'
			: isComparisonTable
			? '24rem'
			: '17.625rem'};
	position: relative;

	img {
		background: linear-gradient(
			249.08deg,
			#e6e5e5 25.57%,
			rgba(249, 247, 247, 0.55) 97.08%
		);
	}

	@media (max-width: 768px) {
		width: ${({ isComparisonTable, $fullWidthCards, isTopPicks }) =>
			$fullWidthCards || isTopPicks
				? 'calc(100vw - 3rem)'
				: isComparisonTable
				? '24rem'
				: '17rem'};
		min-height: ${({ $isPinnedCard }) =>
			$isPinnedCard ? '12.75rem' : '10.5rem'};
	}
`;

export const ProductContent = styled.div<{
	hasDescriptors?: boolean;
	isTopPicks?: boolean;
}>`
	display: grid;
	grid-gap: 0.25rem 0;
	grid-template-columns: 1fr auto;
	grid-template-areas:
		'tour-l2booster tour-rating'
		'tour-name tour-name'
		${({ hasDescriptors }) =>
			hasDescriptors ? `'tour-descriptors tour-descriptors'` : ''}
		'next-available-date next-available-date'
		'tour-price tour-price';
	${({ isTopPicks }) =>
		isTopPicks &&
		`
        padding: 0 0.75rem;
    `}
`;

export const L2Booster = styled.div`
	grid-area: tour-l2booster;
	align-self: center;

	.l2-booster-label {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)};
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 12.1875rem;
	}

	svg {
		height: 1rem;
		width: 1rem;
	}
`;

export const ProductTitle = styled.h3`
	&.product-title {
		grid-area: tour-name;
		color: ${colors.GREY[44]};
		white-space: break-spaces;
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)};
		width: 100%;
		display: -webkit-box !important;
		-webkit-box-orient: vertical !important;
		-webkit-line-clamp: 3;
		overflow: hidden;
		// this is added so that overflow hidden does not clips away smallcased g and y from bottom - DIS-2724
		padding-bottom: 1px;
	}
`;

export const ProductRatingContainer = styled.div`
	grid-area: tour-rating;
	justify-self: right;
	display: flex;
	align-items: center;

	span.rating-count {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)};
	}

	svg {
		margin: 2px 0.125rem 0 0;
		height: 0.75rem;
		width: 0.75rem;
		fill: ${COLORS.TEXT.HEADOUT_CANDY_1};
	}
`;

export const Ratings = styled.div`
	display: grid;
	grid-template-columns: auto auto;

	.product-rating {
		letter-spacing: 0.0313rem;
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;
		color: ${COLORS.TEXT.HEADOUT_CANDY_1};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)};
	}
`;

export const Reviews = styled.span`
	&.review-count {
		margin-left: 0.125rem;
		text-transform: uppercase;
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
	}
`;

export const PriceContainer = styled.div<{ $isPinnedCard?: boolean }>`
	margin-top: ${({ $isPinnedCard }) => ($isPinnedCard ? '2rem' : '0.5rem')};
	grid-area: tour-price;
	color: ${colors.GREY['66']};
`;

export const Descriptors = styled.div`
	grid-area: tour-descriptors;
	display: grid;
	align-items: flex-start;
	grid-row-gap: 0.375rem;
	margin: 0.25rem 0 0.0625rem 0;
`;

export const Descriptor = styled.div`
	display: grid;
	grid-template-columns: 1rem auto;
	grid-column-gap: 0.25rem;
	align-items: end;

	.descriptor-text {
		color: ${colors.GREY['66']};
		font-size: 0.875rem;
		line-height: 1rem;
		font-family: ${fonts.HALYARD.TEXT};
		font-weight: ${fonts.FONT_WEIGHT.LIGHT};
	}

	svg {
		height: 1rem;
		width: 1rem;
	}
`;

export const StyledYourPickContainer = styled.div`
	position: absolute;
	top: -3px;
	left: 0;
	z-index: 11;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: min-content;
	@media (max-width: 768px) {
		top: -5px;
	}
	.your-pick-badge-svg {
		position: absolute;
		@media (max-width: 768px) {
			width: 8.0625rem;
			height: 1.625rem;
		}
	}
	.your-pick-text {
		position: relative;
		bottom: 3px;
		z-index: 2;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;

		color: ${colors.WHITE};

		text-transform: uppercase;
		font-family: halyard-display, sans-serif;
		font-style: italic;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0.6px;
		text-align: center;
		text-shadow: 0px 1px 4px #6600cc;
		font-size: 0.75rem;

		@media (min-width: 768px) {
			text-transform: uppercase;
			font-family: halyard-display, sans-serif;
			font-style: italic;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0.6px;
			text-align: center;
			font-size: 0.875rem;
		}
	}
`;
