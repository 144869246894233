import React, { CSSProperties } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Conditional from 'Components/common/conditional';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const BoosterText = styled.div`
	position: absolute;
	top: 0.5rem;
	left: 0.5rem;
	display: flex;
	align-self: center;
	background: ${colors.WHITE};
	padding: 0.125rem 0.375rem 0.25rem;
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL_HEAVY)};
	border-radius: 0.125rem;
	color: ${colors.GREY['44']};
	z-index: 10;
`;

type Props = {
	boosterText?: string;
	style?: CSSProperties;
};

const L1Booster = ({ boosterText, style }: Props) => (
	<Conditional if={boosterText}>
		<BoosterText css={{ ...style }}>{boosterText}</BoosterText>
	</Conditional>
);

export default L1Booster;
